<template>
	<div class="reserve-waitboard-wrap">
		<div class="reserve-waitboard-num">현재대기 {{ WAIT_TEAM }}명</div>
		<div class="reserve-waitboard-print">
			<span class="reserve-waitboard-print--title">대기번호</span>
			<strong class="reserve-waitboard-print--num">{{ WAIT_NUM }}</strong>
			<p class="reserve-waitboard-print--text">지금 <strong>상담석 {{ WAIT_CS_NUM }}번</strong>으로 오십시오</p>
		</div>

	</div>
	</template>

	<script>
		export default {
			name: 'CAP0300',
			components: {},
			props: {
			},
			data() {
				return {
					WAIT_TEAM: '6', //대기 팀
					WAIT_NUM: '125', //대기 번호
					WAIT_CS_NUM: '5', //상담석 번호
				}
			},
			mounted() {},
			computed: {},
			methods: {

			}
		}
	</script>

	<style>
	</style>